import { reactive } from 'vue'
import { isFunction, isEmpty } from '@shein/common-function'
import apiReport from 'public/src/pages/common/apiReport'
import { getPageAbt, getLanguage } from '../utils/fetcher.js'
import { getProductConfig } from '../utils/productConfig.js'
import { HOOKS } from '../utils/contants'

const needResetState = {
  bindProps: {},
  hooks: {}, // 钩子函数
  addCartNum: 0, // 加车总值
}
const initialState = {
  visible: false,
  abtInfo: {}, // abt
  language: {}, // 多语言
  productConf: {}, // 商卡相关
  triggerPromotionFetch: false,
  ...needResetState,
}

export function fetchAddOnABTLanguageCardConfig(){
  return Promise.all([getPageAbt(), getLanguage(), getProductConfig() ])
}

export function useAddOn(emitter) {
  const state = reactive(initialState)

  const toggle = (show) => {
    state.visible = show
    if (show) {
      apiReport.report({
        apiPath: 'add_popup/bi_parameter_empty',
        parameter_detail: { ...state.bindProps },
      })
    } else { // 关闭时重制数据
      Object.assign(state, needResetState)
    }
  }

  const open = async (config = {}) => {
    const { props, on } = config
    const promiseArr = window.preFetchAddOnABTLanguageCardConfig ? [] : [
      isEmpty(state.abtInfo) ? getPageAbt() : state.abtInfo,
      isEmpty(state.language) ? getLanguage() : state.language,
      isEmpty(state.productConf) ? getProductConfig() : state.productConf,
    ]
    const [abtInfo, language, productConf] = await (window.preFetchAddOnABTLanguageCardConfig || Promise.all(promiseArr))

    // eslint-disable-next-line require-atomic-updates
    window.preFetchAddOnABTLanguageCardConfig = null
    Object.assign(state, {
      abtInfo,
      language,
      productConf,
      bindProps: props || {},
      hooks: on || {},
    })
    state.triggerPromotionFetch = !state.triggerPromotionFetch
    if(state.visible) emitter.emit('EventFetchRecommends')
    toggle(true)
  }

  const close = (info) => {
    executeHook(HOOKS.close, info)
    toggle(false)
  }

  const viewCart = (info) => {
    executeHook(HOOKS.viewCart, info)

    if (state.bindProps?.config?.continueBtnToClose || window.location.href.includes('/cart')) {
      toggle(false)
      return
    }

    setTimeout(() => {
      window.location.href = '/cart'
    })
    
  }

  const executeHook = (name, info) => {
    // 校验接入方入参
    if (!isFunction(state.hooks?.[name])) return
    state.hooks[name](info)
  }

  const updateOpts = (props = {}) => {
    state.bindProps = Object.assign({}, state.bindProps, props)
  }

  const updateAddCartNum = () => {
    state.addCartNum += 1
  }

  return {
    state,
    open,
    close,
    viewCart,
    updateOpts,
    executeHook,
    updateAddCartNum,
  }
}
