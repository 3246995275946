import schttp from 'public/src/services/schttp'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { getAddressInfo } from 'public/src/pages/cart_v2/services/api.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { getAbt } from 'public/src/pages/cart_v2/services/api.js'

export const getPageAbt = async () => {
  // 凑单 相关
  const newKeys = ['addnewhotJson', 'Cartshowcoupon', 'orderCouponNewUser', 'CartaddCouponBottomStyle', 'CartaddPromotionBottomStyle', 'cartautopoints', 'CartAddTechnicalSwitch']
  const abtInfo = await getAbt({ newPosKeys: newKeys })
  return abtInfo
}

export const getLanguage = async () => {
  const res = await schttp({ 
    url: '/api/common/language/get', 
    params: { page: 'filter' }
  })
  return res.language || {}
}

/* 获取推荐列表数据 */
export const getLists = (params) => {
  return schttp({
    url: `/api/recommend/facadeAtom/get`,
    method: 'POST',
    data: params
  })
}

/* 兜底推荐容错列表接口 */
export const getInsuranceList = (params) => {
  return schttp({
    url: `/api/recommend/faultFacade/query`,
    method: 'POST',
    data: params
  })
} 

const getIntegrityPromotionInfo = async (products) => {
  const ids = []
  const asyncPromotionIds = ['2', '14', '15', '24', '28']
  const new_products = []

  products?.forEach(item => {
    let new_item = { promotionInfo: [] }
    item.promotionInfo?.forEach(info => {
      const id = String(info.id)
      const typeId = String(info.typeId)
      if (asyncPromotionIds.includes(typeId) && !ids.includes(id)) {
        ids.push(id)
        new_item.promotionInfo.push({
          id,
          typeId,
        })
      }
    })
    if(new_item.promotionInfo.length) {
      new_products.push(new_item)
    }
  })
  
  if(!new_products.length) { 
    return {} 
  }
  const { data: promotionInfoFromServer = {} } = await schttp({
    method: 'POST',
    url: '/api/productInfo/postIntegrityPromotionInfo/get',
    data: {
      products: new_products,
    }
  })
  return promotionInfoFromServer
}

export const getInterfaceList = (params, itemConfig, config) => {
  return new Promise((resolve, reject) => {
    getLists(params)
      .then(async (res) => {
        let products = []
        // excludeSubsidiesGoods 是否排除百亿补贴商品
        if (config.excludeSubsidiesGoods) {
          let subsidiesGoodsNum = 0
          products = (res?.info?.products || []).filter(item => {
            if ((item.promotionInfo || []).find(info => +info.typeId === 31 && +info.promotion_logo_type === 21 )) {
              subsidiesGoodsNum++
              return false
            }
            return true
          })
          if ((res?.info?.products || []).length > 0 && subsidiesGoodsNum > 0) {
            if (subsidiesGoodsNum >= (res?.info?.products || []).length) {
              SILogger.logInfo({
                tag: 'add_on_empty_by_subsidies_goods',
                message: 'add on empty by subsidies goods',
              })
            } else {
              SILogger.logInfo({
                tag: 'add_on_subsidies_goods_rate',
                message: 'add on subsidies goods rate',
                params: {
                  rate: `${(subsidiesGoodsNum / (res?.info?.products || []).length * 100).toFixed(2)}%`,
                }
              })
            }
          }
        } else {
          products = res?.info?.products || []
        }

        try {
          // 实时获取促销信息
          let promotionInfoFromServer = {}
          try {
            promotionInfoFromServer = await getIntegrityPromotionInfo(products)
          } catch (error) {
            console.error('getIntegrityPromotionInfo', error)
          }
          
          // const { data: promotionInfoFromServer = {} } = await schttp({
          //   method: 'POST',
          //   url: '/api/productInfo/postIntegrityPromotionInfo/get',
          //   data: {
          //     products,
          //   },
          // })
          products = dealProductsPretreatInfo({
            products,
            itemConfig: itemConfig,
            promotionInfoFromServer,
          })
        } catch (error) {
          // console.log('promotionInfoFromServer', error)
        }

        resolve({ ...res, products, total: res?.info?.total })
      })
      .catch(() => {
        getInsuranceList({
          ...params,
          rec_type: 1, // 推荐类型: (1:商品  4:filter类目)
          pagename: 'page_for_freeshipping',
        })
          .then(async (res) => {
            resolve({ ...res, products: res?.info?.products, total: res?.info?.total, isFaultTolerant: true })
          })
          .catch(reject)
      })
  })
}

/* 获取券数据 */
export const getCouponInfo = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/coupon/addItemInfo',
    data: data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * @description: pageId=1612929667
 * @param { Object } data
 * @param { String } data.popupInfoId 弹窗id
 * @param { Number } data.addItemType 弹窗映射大类, 枚举值 promotion, user_growth, free_shipping
 * @param { String } data.lurePointCacheInfo 利诱点缓存数据
 * @param { String } data.excludeBubbles 利诱点排除气泡
 * @param { Object } data.freeShippingParams 免邮类型是需传入, 中间层用于对比活动变化
 * @return {*}
 */
export const getPromotionInfo = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()
  const promotionId = data?.addItemType === 'promotion' ? data?.popupInfoId : undefined
  const activityId = data?.addItemType !== 'promotion' ? data?.popupInfoId : undefined
  const params = {
    cartParams: {
      country_id: addressInfo?.countryId,
      state: addressInfo?.state || '',
      city: addressInfo?.city || '',
      district: addressInfo?.district || '',
      postcode: addressInfo?.postcode || '',
      primeProductCode: '',
    },
    type: data?.addItemType,
    typeData: {
      promotionId,
      activityId, // 免邮活动id, user_growth活动id
      mallCode: '', // 免邮活动需传入
      lastActivityType: '', // 上一个免邮活动类型
      lastActivityId: '', // 上一个免邮活动id
      lastNowLevel: '', // 上一个运费活动当前档次值
      lastTransportType: '', // 上一个运输方式类型
      ...(data?.freeShippingParams || {})
    },
    lureParams: {
      lurePointCacheInfo: data?.lurePointCacheInfo, // 利诱点缓存数据
      excludeBubbles: data?.excludeBubbles, // 需要排除的气泡
    },
  }

  return schttp({
    method: 'POST',
    url: '/cart-api/activity/add_item_info',
    data: params,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}
